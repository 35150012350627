import { Component, OnInit } from '@angular/core';
import { IAppState } from './store';
import { Store } from '@ngrx/store';
import { syncArchive } from './store/actions/archive.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ChoirBuddy';

  constructor(private store: Store<IAppState>) {
  }

  ngOnInit() {
  }
}
