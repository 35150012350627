
export function isSecure(): boolean {
  return window.location.protocol === 'https:';
}

export function matchSecurity(url: string) {
  if (isSecure()) {
    return url.replace('http://', 'https://');
  } else {
    return url.replace('https://', 'http://');
  }
}
