import { Component, OnInit, Input } from '@angular/core';
import { IAppState, removeSongFromList, addSongToList } from '../store';
import { Store, select } from '@ngrx/store';
import { selectFavouriteSongList } from '../store/selectors';
import { favouriteSongListId } from '../models/models';

@Component({
  selector: 'app-add-remove-song-fav',
  templateUrl: './add-remove-song-fav.component.html',
  styleUrls: ['./add-remove-song-fav.component.scss']
})
export class AddRemoveSongFavComponent implements OnInit {

  public isFavorite: boolean;

  constructor(private store: Store<IAppState>) {  }

  @Input() songId: number;

  ngOnInit() {
    this.store.pipe(select(selectFavouriteSongList)).subscribe(list => {
      this.isFavorite = list.songIds.includes(this.songId);
    });
  }

  public removeFromFav(): void {
    this.store.dispatch(removeSongFromList({songId: this.songId, listId: favouriteSongListId}));
  }
  public addToFav(): void {
    this.store.dispatch(addSongToList({songId: this.songId, listId: favouriteSongListId}));
  }

}
