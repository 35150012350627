import {Injectable} from '@angular/core';
import {
  Actions,
  Effect,
  ofType
} from '@ngrx/effects';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { syncArchive, updateArchive } from '../actions/archive.actions';
import { switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Archive } from 'src/app/models/models';
import { updateSongLists, updateSongs } from '../actions';
import { IAppState } from '..';
import { matchSecurity } from 'src/app/utils';

@Injectable()
export class ArchiveEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient) {
  }

  @Effect()
  refreshSongLists$: Observable<Action> = this.actions$.pipe(
    ofType(syncArchive),
    switchMap( (action) => {
      const url = matchSecurity(`http://wiki.masterolofskoren.se/mp3archive/cbdata/${action.archiveName}.json`);
      return this.http.get<Archive>(url).pipe(
        switchMap(archive => {
          archive.lists.forEach(list => {
            list.date = Date.parse(list.dateStr);
            list.imgUrl = `https://picsum.photos/id/${list.id}/150/150`;
          });

          return [
            updateArchive({archiveName: action.archiveName, urlPrefix: archive.urlPrefix}),
            updateSongs({songs: archive.songs}),
            updateSongLists({lists: archive.lists})
          ];
        },
        // return a Failed action when something went wrong
       // catchError(error => of(new `syncArchive`(error))),
      ));
    }));
}
