import { Component, OnInit } from '@angular/core';
import { PlayerService, LyricsSyllable } from '../player.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-lyrics',
  templateUrl: './lyrics.component.html',
  styleUrls: ['./lyrics.component.scss']
})
export class LyricsComponent implements OnInit {

  public lyrics: LyricsSyllable[][];
  public currentSyllable: number;
  constructor(private player: PlayerService) {
    player.currentLyrics$.subscribe(l => this.lyrics = l);
    player.positionInTicks$.subscribe(tick => this.selectSyllable(tick));
  }

  private selectSyllable(tick: number) {

    this.lyrics.forEach(row => row.forEach(s => {
      if (s.tick < tick) {
        this.currentSyllable = s.index;
        return;
      }
    }));
  }

  ngOnInit() {
  }
}
