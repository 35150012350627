import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Router } from '@angular/router';
import { IAppState, syncArchive, ClearStore, updateUserLoggedIn, updateUserProfile } from '../store';
import { Store, select } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { selectUrlPrefix } from '../store/selectors';
import { voices } from '../models/models';
import { match } from 'minimatch';
import { matchSecurity } from '../utils';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  emailControl = new FormControl('', [Validators.required, Validators.email]);
  matcher = new MyErrorStateMatcher();
  private urlPrefix: string;
  public username: string;
  public password: string;
  constructor(private router: Router, private store: Store<IAppState>, private httpClient: HttpClient) { }

  ngOnInit() {
    this.store.pipe(select(selectUrlPrefix)).subscribe(p => this.urlPrefix = p);
  }

  public login(): void {
    this.doLogin(this.username, this.password);
  }

  public trial(): void {
    this.doLogin('CB', '');
  }

  private async doLogin(username: string, password: string): Promise<void> {
    try {
      const url = matchSecurity(`${this.urlPrefix}cbdata/${username}.json`);
      const res = await this.httpClient.head(url).toPromise();
      console.log(res);
      this.store.dispatch(new ClearStore());

      this.store.dispatch(updateUserLoggedIn({loggedIn: true}));
      this.store.dispatch(updateUserProfile({profile: {name: username, voice: voices[0]}}));

      this.store.dispatch(syncArchive({archiveName: username}));
      this.router.navigate(['/overview']);
    } catch {
      console.log('login failed');
    }
  }
}
