import { Component, OnInit } from '@angular/core';
import { SongList, Archive } from '../models/models';
import { Observable } from 'rxjs/internal/Observable';
import { IAppState } from '../store';
import { Store, select } from '@ngrx/store';
import { selectRecentSongLists } from '../store/selectors';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  public recentLists$: Observable<SongList[]>;

  constructor(private store: Store<IAppState>) {
  }

  ngOnInit() {
    this.recentLists$ = this.store.pipe(
      select(selectRecentSongLists)
    );
  }
}
