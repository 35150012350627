import {
  createReducer,
  on,
  Action
} from '@ngrx/store';
import {
  updateSongLists, setCurrentSongList, addSongToList, removeSongFromList
} from '../actions/songlist.actions';
import { SongList, favouriteSongListId } from '../../models/models';
import { getLocaleDateTimeFormat } from '@angular/common';

export interface ISongListState {
  songLists: {[id: number]: SongList};
  currentSongList: number;
}

const emptyFavList: SongList = {
    id: favouriteSongListId,
    name: 'Favoriter',
    description: 'Här kan du samla dina favoritlåtar',
    ordered: true,
    date: Date.now(),
    dateStr: '2019-11-26',
    lastUsedDate: Date.now(),
    songIds: [],
    imgUrl: '/assets/heart_large.png'
};

export let initialState: ISongListState = {
  songLists: {
    [favouriteSongListId]: emptyFavList
  },
  currentSongList: favouriteSongListId
};

// tslint:disable-next-line: variable-name
const _songListReducer = createReducer(initialState,
  on(updateSongLists, (state, { lists }) => {
    const res: ISongListState = {
      songLists: {},
      ...state
    };
    const newSongLists: {[id: number]: SongList} = {
      ...state.songLists
    };
    lists.forEach(l => {
      newSongLists[l.id] = {
        ...l
      };
      if (l.lastUsedDate === undefined) {
        newSongLists[l.id].lastUsedDate = l.date;
      }
    });

    res.songLists = newSongLists;
    return res;
  }),

  on(setCurrentSongList, (state, { id }) => {
    return {
      ...state,
      currentSongList : id,
      songLists: {
        ...state.songLists,
        [id]: {
          ...state.songLists[id],
          lastUsedDate: Date.now()
        }
      }
    };
  }),

  on(addSongToList, (state, { songId, listId }) => {
    return {
      ...state,
      songLists: {
        ...state.songLists,
        [listId]: {
          ...state.songLists[listId],
          songIds: state.songLists[listId].songIds.concat(songId)
        }
      }
    };
  }),

  on(removeSongFromList, (state, { songId, listId }) => {
    return {
      ...state,
      songLists: {
        ...state.songLists,
        [listId]: {
          ...state.songLists[listId],
          songIds: state.songLists[listId].songIds.filter(id => id !== songId)
        }
      }
    };
  })

);

export function songListReducer(state: ISongListState, action: Action): ISongListState {
  return _songListReducer(state, action);
}
