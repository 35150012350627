import {
  createReducer,
  on,
  Action,
  State
} from '@ngrx/store';

import { Song } from '../../models/models';
import { updateSongs, setCurrentSong } from '../actions';

export interface ISongState {
  songs: {[id: number]: Song};
  currentSong: number;
}

export let initialState: ISongState = {
  songs: {},
  currentSong: -1
};

// tslint:disable-next-line: variable-name
const _songReducer = createReducer(initialState,
  on(updateSongs, (state, { songs }) => {
    const res: ISongState = {
      ...state
    };

    const newSongs: {[id: number]: Song} = {
      ...state.songs
    };
    songs.forEach(l => newSongs[l.id] = l);
    if (res.currentSong === -1 && songs.length > 0) {
      res.currentSong = songs[0].id;
    }

    res.songs = newSongs;
    return res;
  }),

  on(setCurrentSong, (state, { id }) => {
    return {
      ...state,
      currentSong : id,
      songs: {
        ...state.songs,
        id: {
          ...state.songs[id],
          lastViewed: Date.now()
        }
      }
    };
  })
);

export function songReducer(state: ISongState, action: Action): ISongState {
  return _songReducer(state, action);
}
