import * as fromRouter from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAppState } from '../reducers';
import { SongList, Song, favouriteSongListId } from 'src/app/models/models';
import { ISongListState } from '../reducers/songlist.reducers';
import { ISongState } from '../reducers/song.reducers';
import { IArchiveState } from '../reducers/archive.reducers';
import { IUserProfileState } from '../reducers/userProfile.reducers';

export const selectRouter = createFeatureSelector<
  IAppState,
  fromRouter.RouterReducerState<any>
>('router');

const {
  selectQueryParams,    // select the current route query params
  selectQueryParam,     // factory function to select a query param
  selectRouteParams,    // select the current route params
  selectRouteParam,     // factory function to select a route param
  selectRouteData,      // select the current route data
  selectUrl,            // select the current url
} = fromRouter.getSelectors(selectRouter);

export const selectRouteId = selectRouteParam('id');

export const selectSongListState =  createFeatureSelector<IAppState, ISongListState>('songList');
export const selectSongState =  createFeatureSelector<IAppState, ISongState>('song');
export const selectArchiveState =  createFeatureSelector<IAppState, IArchiveState>('archive');
export const selectUserProfileState =  createFeatureSelector<IAppState, IUserProfileState>('userProfile');

export const selectOrderedSongLists = createSelector(
  selectSongListState,
  (s: ISongListState) => {
    const list: SongList[] = Object.keys(s.songLists).map(key => s.songLists[key]);
    return list.sort((l1, l2) => l2.date - l1.date);
  }
);

export const selectRecentSongLists = createSelector(
  selectSongListState,
  (s: ISongListState) => {
    const list: SongList[] = Object.keys(s.songLists).map(key => s.songLists[key]);
    return list.sort((l1, l2) => l2.lastUsedDate - l1.lastUsedDate).slice(0, 3);
  }
);

export const selectSongListByRouteId = createSelector(
  selectRouteParam('id'),
  selectSongListState,
  (id, songListState) => {
    if (id === 'favourites') {
      id = favouriteSongListId.toString();
    }
    return songListState.songLists[id];
  }
);

export const selectSongListSongs = createSelector(
  selectSongListByRouteId,
  selectSongState,
  (songList: SongList, songState: ISongState) => {
    if (songList === undefined) {
      return new Array<Song>();
    }
    return songList.songIds.map((songId) => songState.songs[songId]);
  }
);

export const selectUrlPrefix = createSelector(
  selectArchiveState,
  archiveState => archiveState.urlPrefix
);


export const selectArchiveName = createSelector(
  selectArchiveState,
  archiveState => archiveState.archiveName
);

export const selectCurrentSong = createSelector(
  selectSongState,
  songState => songState.songs[songState.currentSong]
);

export const selectCurrentSongList = createSelector(
  selectSongListState,
  songListState => songListState.songLists[songListState.currentSongList]
);

export const selectFavouriteSongList = createSelector(
  selectSongListState,
  songListState => songListState.songLists[favouriteSongListId]
);



export const selectUserProfile = createSelector(
  selectUserProfileState,
  userProfileState => userProfileState.userProfile
);


export const selectUserLoggedIn = createSelector(
  selectUserProfileState,
  userProfileState => userProfileState.loggedIn
);
