import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { updateSongLists } from '../store/actions/songlist.actions';
import { SongList } from '../models/models';
import { IAppState } from '../store/reducers';
import { syncArchive } from '../store/actions/archive.actions';
import { ClearStore } from '../store/actions/meta.actions';
import { selectArchiveName } from '../store/selectors';

@Component({
  selector: 'app-devstuff',
  templateUrl: './devstuff.component.html',
  styleUrls: ['./devstuff.component.scss']
})
export class DevstuffComponent implements OnInit {
  archiveName: string;

  constructor(private store: Store<IAppState>) { }

  ngOnInit() {
    this.store.pipe(select(selectArchiveName)).subscribe(n => this.archiveName = n);
  }

  public updateSonglist() {
    const newlist: SongList = {
      id: 7,
      name: 'Höstlista 19 (' + Math.round(Math.random() * 100) + ')',
      ordered: false,
      description: 'Låter som repeteras under höstterminen 2019',
      imgUrl: 'https://images.unsplash.com/photo-1470093851219-69951fcbb533',
      dateStr: '2018-01-01',
      date: undefined,
      lastUsedDate: Date.now(),
      songIds: [13, 14],
    };
    this.store.dispatch(updateSongLists({lists: [newlist]}));
  }

  public refresh() {
    this.store.dispatch(syncArchive({archiveName: this.archiveName}));
  }

  public clear() {
    this.store.dispatch(new ClearStore());
  }

}
