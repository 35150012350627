import { Component, OnInit, ComponentFactoryResolver } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { IAppState, ClearStore, updateUserProfile } from '../store';
import { Store, select } from '@ngrx/store';
import { Voice, UserProfile, voices } from '../models/models';
import { selectUserProfile } from '../store/selectors';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  email = new FormControl('', [Validators.required, Validators.email]);

  public optionX = true;
  public optionY = false;
  public selectedVoice: string = voices[0].value;

  public voices: Voice[] = voices;

  constructor(private store: Store<IAppState>, private router: Router) { }

  ngOnInit() {
    this.store.pipe(select(selectUserProfile)).subscribe(p => {
      this.selectedVoice = p.voice.value;
    });
  }

  getErrorMessage() {
    return this.email.hasError('required') ? 'You must enter a value' :
        this.email.hasError('email') ? 'Not a valid email' :
            '';
  }

  public logout(): void {
    this.store.dispatch(new ClearStore());
    this.router.navigate(['login']);
  }

  public saveSettings(): void {
    const theVoice = voices.filter(v => v.value === this.selectedVoice)[0];
    const profile: Partial<UserProfile> = {
      voice: theVoice
    };

    this.store.dispatch(updateUserProfile({profile}));
  }
}
