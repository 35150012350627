import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { SongList, Song, Archive } from '../models/models';
import { Observable, BehaviorSubject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { IAppState, setCurrentSong, setCurrentSongList } from '../store';
import { selectSongListByRouteId, selectSongListSongs, selectUrlPrefix } from '../store/selectors';
import { Routes, Router } from '@angular/router';

@Component({
  selector: 'app-songlist',
  templateUrl: './songlist.component.html',
  styleUrls: ['./songlist.component.scss']
})
@Injectable()
export class SonglistComponent implements OnInit {

  public songList$: Observable<SongList> = new Observable<SongList>();
  public songs$: Observable<Song[]> = new Observable<Song[]>();
  public urlPrefix$: Observable<string> = new Observable<string>();

  constructor(private store: Store<IAppState>, private router: Router) {
   }


  ngOnInit() {
    this.songList$ = this.store.pipe(select(selectSongListByRouteId));

    this.songs$ = this.store.pipe(select(selectSongListSongs));
    this.urlPrefix$ = this.store.pipe(select(selectUrlPrefix));
  }

  public startPlaying(listId: number, songId: number) {
    this.store.dispatch(setCurrentSong({id: songId}));

    this.store.dispatch(setCurrentSongList({id: listId}));
    this.router.navigate(['player']);
  }
}
