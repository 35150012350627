import {
  createReducer,
  on,
  Action
} from '@ngrx/store';

import { updateArchive } from '../actions';

export interface IArchiveState {
  urlPrefix: string;
  archiveName: string;
}

export let initialState: IArchiveState = {
  urlPrefix: 'http://wiki.masterolofskoren.se/mp3archive/',
  archiveName: 'cb'
};

// tslint:disable-next-line: variable-name
const _archiveReducer = createReducer(initialState,
  on(updateArchive, (state, { urlPrefix, archiveName }) => {
    const res: IArchiveState = {
      ...state,
      urlPrefix,
      archiveName
    };
    return res;
  })
);

export function archiveReducer(state: IArchiveState, action: Action): IArchiveState {
  return _archiveReducer(state, action);
}
