import {
  createReducer,
  on,
  Action
} from '@ngrx/store';
import { UserProfile, Voice, voices } from 'src/app/models/models';
import { updateUserProfile, updateUserLoggedIn } from '../actions';

export interface IUserProfileState {
  userProfile: Partial<UserProfile>;
  loggedIn: boolean;
}

export let initialState: IUserProfileState = {
  userProfile: {
    name: 'unknown',
    voice: voices[0],
  },
  loggedIn: false
};

// tslint:disable-next-line: variable-name
const _userProfileReducer = createReducer(initialState,
  on(updateUserProfile, (state, { profile }) => {
    return {
      ...state,
      userProfile: {
        ...state.userProfile,
        ...profile
      }
    };
  }),
  on(updateUserLoggedIn, (state, {loggedIn}) => {
    return {
      ...state,
      loggedIn
    };
  })

);

export function userProfileReducer(state: IUserProfileState, action: Action): IUserProfileState {
  return _userProfileReducer(state, action);
}
