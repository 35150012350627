import {
  createAction,
  props
} from '@ngrx/store';
import { SongList } from '../../models/models';

export const updateSongLists = createAction('[SongList] Update', props<{lists: SongList[]}>());

export const setCurrentSongList = createAction('[SongList] SetCurrent', props<{id: number}>());

export const addSongToList = createAction('[SongList] AddSong', props<{songId: number, listId: number}>());
export const removeSongFromList = createAction('[SongList] removeSong', props<{songId: number, listId: number}>());
