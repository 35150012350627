import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OverviewComponent } from './overview/overview.component';
import { SonglistComponent } from './songlist/songlist.component';
import { AllListsComponent } from './all-lists/all-lists.component';
import { PlayerComponent } from './player/player.component';
import { SearchComponent } from './search/search.component';
import { DevstuffComponent } from './devstuff/devstuff.component';
import { ProfileComponent } from './profile/profile.component';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './auth-guard.service';


const routes: Routes = [
  {path: 'login', component: LoginComponent},

  {path: 'overview', component: OverviewComponent, canActivate: [AuthGuardService]},
  {path: 'songlist/:id', component: SonglistComponent, canActivate: [AuthGuardService]},
  {path: 'all-lists', component: AllListsComponent, canActivate: [AuthGuardService]},
  {path: 'player', component: PlayerComponent, canActivate: [AuthGuardService]},
  {path: 'search', component: SearchComponent, canActivate: [AuthGuardService]},
  {path: 'profile', component: ProfileComponent, canActivate: [AuthGuardService]},
  {path: 'devstuff', component: DevstuffComponent, canActivate: [AuthGuardService]},

  {path: '', redirectTo: 'overview', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
