import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { SonglistComponent } from './songlist/songlist.component';
import { PlayerComponent } from './player/player.component';
import { OverviewComponent } from './overview/overview.component';
import { AllListsComponent } from './all-lists/all-lists.component';
import { SearchComponent } from './search/search.component';
import { LyricsComponent } from './lyrics/lyrics.component';

import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './store/reducers';
import { DevstuffComponent } from './devstuff/devstuff.component';

import {StoreDevtoolsModule} from '@ngrx/store-devtools';

import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';

import {
  effects,
} from './store';
import { EffectsModule } from '@ngrx/effects';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatSliderModule } from '@angular/material/slider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonModule} from '@angular/material/button';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AddRemoveSongFavComponent } from './add-remove-song-fav/add-remove-song-fav.component';


@NgModule({
  declarations: [
    AppComponent,
    SonglistComponent,
    PlayerComponent,
    OverviewComponent,
    AllListsComponent,
    SearchComponent,
    LyricsComponent,
    DevstuffComponent,
    LoginComponent,
    ProfileComponent,
    AddRemoveSongFavComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
//        strictStateSerializability: true,
//        strictActionSerializability: true
      },
    }),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      name: 'ChoirBuddy',
      logOnly: true,
      maxAge: 25,
      features: {
        pause: true, // start/pause recording of dispatched actions
        lock: false, // lock/unlock dispatching actions and side effects
        persist: false, // persist states on page reloading
        export: true, // export history of actions in a file
        import: 'custom', // import history of actions from a file
        jump: true, // jump back and forth (time travelling)
        skip: true, // skip (cancel) actions
        reorder: true, // drag and drop actions in the history list
        dispatch: true, // dispatch custom actions or action creators
        test: true // generate tests for the selected actions
      }
    }),
    BrowserAnimationsModule,
    MatSliderModule,
    MatExpansionModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
