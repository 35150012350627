
import * as fromGlobals from '../actions/meta.actions';
import { Action } from '@ngrx/store';

// eslint-disable-next-line func-style
export function clearState(reducer) {
  // tslint:disable-next-line: only-arrow-functions
  return function(state, action: Action) {
    if (action.type === fromGlobals.CLEAR_STATE) {
      state = {};
    }

    return reducer(state, action);
  };
}
