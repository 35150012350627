import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { IAppState } from './store';
import { Store, select } from '@ngrx/store';
import { selectUserProfile, selectUserLoggedIn } from './store/selectors';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  implements CanActivate {
  isLoggedIn: boolean;
  constructor(
    private store: Store<IAppState>,
    public router: Router
  ) {
    store.pipe(select(selectUserLoggedIn)).subscribe(x => {
      return this.isLoggedIn = x;
    });
  }

  canActivate(): boolean {
    if (!this.isLoggedIn) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}
