import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';

import { environment } from '../../../environments/environment';

import { ISongListState, songListReducer } from './songlist.reducers';
import { ISongState, songReducer } from './song.reducers';

import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { localStorageSync, LocalStorageConfig } from 'ngrx-store-localstorage';
import { IArchiveState, archiveReducer } from './archive.reducers';
import { clearState } from './meta.reducers';
import { IUserProfileState, userProfileReducer } from './userProfile.reducers';

export interface IAppState {
  songList: ISongListState;
  song: ISongState;
  archive: IArchiveState;
  router: RouterReducerState<any>;
  userProfile: IUserProfileState;
}

export const reducers: ActionReducerMap<IAppState> = {
  songList: songListReducer,
  song: songReducer,
  archive: archiveReducer,
  router: routerReducer,
  userProfile: userProfileReducer
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  const config: LocalStorageConfig = {
    keys: ['song', 'songList', 'archive', 'userProfile'],
    storageKeySerializer: (key) => 'choirbuddy_' + key,
    storage: localStorage,
    rehydrate: true
  };
  return localStorageSync(config)(reducer);
}

export const metaReducers: MetaReducer<IAppState>[] = !environment.production ?
[
  localStorageSyncReducer,
  clearState
]
:
[
  localStorageSyncReducer,
  clearState
];
