import { Component, OnInit } from '@angular/core';
import { SongList } from '../models/models';
import { Observable } from 'rxjs';
import { IAppState } from '../store';
import { Store, select } from '@ngrx/store';
import { selectOrderedSongLists as selectOrderedSongLists } from '../store/selectors';

@Component({
  selector: 'app-all-lists',
  templateUrl: './all-lists.component.html',
  styleUrls: ['./all-lists.component.scss']
})
export class AllListsComponent implements OnInit {

  public songLists$: Observable<SongList[]>;

  constructor(private store: Store<IAppState>) {
  }

  ngOnInit() {
    this.songLists$ = this.store.pipe(select(selectOrderedSongLists));
  }
}
