import { Component, OnInit, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { skipWhile } from 'rxjs/operators';

import { Song } from '../models/models';
import { PlayerService } from '../player.service';
import { IAppState } from '../store';
import { selectCurrentSong } from '../store/selectors';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit {
  private _currentPosition: number;
  private _currentTempo: number;

  public currentSong$: Observable<Song>;

  public get currentPosition(): number {
    return this._currentPosition;
  }
  public set currentPosition(value) {
    this._currentPosition = value;
    this.player.seek(value);
  }

  public get currentTempo(): number {
    return this._currentTempo;
  }

  public set currentTempo(value) {
    this._currentTempo = value;
    this.player.setTempo(value);
  }

  public duration$: Observable<number>;
  public isPlaying$: Observable<boolean>;
  public song$: Observable<Song>;
  public trackNames$: Observable<string[]>;


  constructor(private player: PlayerService, private store: Store<IAppState>) {
    player.position$.subscribe(p => this._currentPosition = p);
    player.tempo$.subscribe(t => this._currentTempo = t);
    this.duration$ = player.duration$;
    this.isPlaying$ = player.isPlaying$;
    this.trackNames$ = player.tracknames$;
  }

  ngOnInit() {
    this.currentSong$ = this.store.pipe(
      select(selectCurrentSong),
      skipWhile( v => !v )
    );
  }

  public next() {
    this.player.nextSong();
  }

  public rewindToStart() {
    this.player.rewindToStart();
  }

  public setPiano(track: string) {
    this.player.setPiano(track);
    this.player.setVolume(track, 50);
  }

  public setMute(track: string) {
    this.player.setVolume(track, 0);
  }

  public setOboe(track: string) {
    this.player.setOboe(track);
    this.player.setVolume(track, 100);
  }

  public setVoice(track: string) {
    this.player.setVoice(track);
  }

  public pause() {
    this.player.pause();
  }

  public rewind(seconds: number) {
    this.player.rewind(seconds);
  }

  public play() {
    this.player.play();
  }
}
